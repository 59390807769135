:root {
  /* Color Variables */
  --primary-main: #1976d2;
  --primary-light: #5eb8ff;
  --primary-dark: #004ba0;
  --primary-background: rgba(25, 118, 210, 0.08);

  --secondary-main: #455a64;
  --secondary-light: #718792;
  --secondary-dark: #1c313a;

  --error-main: #e53935;
  --error-light: #ef5350;
  --error-dark: #c62828;

  --warning-main: #ffa000;
  --warning-light: #ffcd38;
  --warning-dark: #c67100;

  --success-main: #4caf50;
  --success-light: #80e27e;
  --success-dark: #087f23;

  --text-primary: #263238;
  --text-secondary: #546e7a;
  --text-disabled: #b0bec5;

  --background-default: #f9fbfd;
  --background-paper: #ffffff;
  --divider: #eceff1;

  /* Shadow Variables */
  --shadow-sm: 0 2px 8px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 12px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 8px 24px rgba(0, 0, 0, 0.12);

  /* Animation Variables */
  --transition-fast: 0.15s ease;
  --transition-normal: 0.25s ease;
  --transition-slow: 0.35s ease;

  /* Sizing Variables */
  --border-radius-sm: 4px;
  --border-radius-md: 8px;
  --border-radius-lg: 12px;

  /* Animation Curves */
  --ease-out-quad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);
  --ease-out-quint: cubic-bezier(0.23, 1, 0.32, 1);
  --ease-in-quad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
  --ease-in-cubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  --ease-in-quint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
  --ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  --ease-in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1);
  --ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
}

html {
  overflow-y: overlay;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #263238;
  background-color: #f9fbfd;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.2s ease;
}

a:hover {
  color: #1976d2;
}

button {
  transition: all 0.2s ease;
}

/* Improved Focus Styles */
*:focus {
  outline: none;
}

*:focus-visible {
  outline: 2px solid #1976d2;
  outline-offset: 2px;
}

/* Transition for all interactive elements */
button,
a,
input,
select,
textarea,
.MuiButtonBase-root,
.MuiPaper-root,
.MuiCard-root {
  transition: all 0.2s ease-in-out;
}

/* Scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

#login {
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
}

input[id^='react-select-'] {
  opacity: 1 !important;
}

@keyframes jiggle {
  0% {
    transform: rotate(-2deg);
  }

  50% {
    transform: rotate(2deg);
  }
}

@-moz-keyframes jiggle {
  0% {
    -moz-transform: rotate(-2deg);
  }

  50% {
    -moz-transform: rotate(2deg);
  }
}

@-webkit-keyframes jiggle {
  0% {
    -webkit-transform: rotate(-2deg);
  }

  50% {
    -webkit-transform: rotate(2deg);
  }
}

#CancelOrderDialog div .MuiPaper-root {
  max-width: 750px !important;
}

#ManageAccessorialsDialog div .MuiPaper-root {
  max-width: 750px !important;
}

#LyftRideDialog div .MuiPaper-root {
  max-width: 800px !important;
  min-width: 800px !important;
}

#CarmaxInvoiceDialog div .MuiPaper-root {
  max-width: 90vw !important;
  min-width: 90vw !important;
}

@keyframes pulse {
  0% {
    filter: brightness(80%);
  }
  45% {
    filter: brightness(100%);
  }
  80% {
    box-shadow: 0 0 0 8px #00000000;
  }
  100% {
    filter: brightness(80%);
    box-shadow: 0 0 0 0 #00000000;
  }
}